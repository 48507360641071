body {
  background-color: #f8f8f8;
  min-height: 2000px;
  padding-top: 54px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
}
#datePickerLabel {
  margin-bottom: 1px;
}

#selectDep{
  min-width: 250px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ui.borderless.menu {
  background-color: #f8f8f8;
  box-shadow: none;
  flex-wrap: wrap;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.ui.borderless.menu .header.item {
  font-size: 18px;
  font-weight: 400;
}
.ui.mobile.only.grid .ui.menu .ui.vertical.menu {
  display: none;
}

.ui.mobile.only.grid .ui.vertical.menu .dropdown.icon {
  float: unset;
}

.ui.mobile.only.grid .ui.vertical.menu .dropdown.icon:before {
  content: "\f0d7";
}

.ui.mobile.only.grid .ui.vertical.menu .ui.dropdown.item .menu {
  position: static;
  width: 100%;
  background-color: unset;
  border: none;
  box-shadow: none;
}

.ui.mobile.only.grid .ui.vertical.menu .ui.dropdown.item .menu {
  margin-top: 6px;
}

#first {
  padding-top: 1rem;
  margin-top: 1rem;
}